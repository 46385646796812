<template>
    <div class="section">
        <el-button @click="test1">全局的Minix</el-button>
        <el-button @click="test2('使用了局部minix')">局部的Minix</el-button>
        <el-button @click="onTest">setup内部使用</el-button>
    </div>
</template>
<style  lang="scss" scoped>

</style>
<script>
import {getCurrentInstance} from "vue";
// import {WOW} from 'wowjs' 
import 'animate.css'
import {common} from '../minix/index.js'
export default {
    mixins:[common],
    components:{

    },
    setup(){
        const { proxy } = getCurrentInstance()
        const onTest = () =>{
            proxy.test2('setup内部使用Minx')
        }
        return{
            onTest
        }
    }
}
</script>